import { VercelSortKeys, vercelToBigCommerceSortKeys } from "./constants";

const bigCommerceToVercelOptions = (options) => {
  return options.map((option) => {
    return {
      id: option.entityId.toString(),
      name: option.displayName.toString(),
      values: option.values
        ? option.values.edges.map(({ node: value }) => value.label)
        : [],
    };
  });
};

export const vercelFromBigCommerceLineItems = (lineItems) => {
  const { physicalItems, digitalItems, customItems } = lineItems;
  const cartItemMapper = ({ entityId, quantity, productEntityId }) => ({
    merchandiseId: productEntityId
      ? productEntityId.toString()
      : entityId.toString(),
    quantity,
  });

  return [physicalItems, digitalItems, customItems].flatMap((list) =>
    list.map(cartItemMapper)
  );
};

const bigCommerceToVercelVariants = (variants, productId) => {
  return variants.map((variant) => {
    return {
      parentId: productId.toString(),
      id: variant.entityId.toString(),
      title: "",
      availableForSale: variant.isPurchasable,
      selectedOptions: variant.options?.edges.map(({ node: option }) => ({
        name: option.displayName ?? "",
        value: option.values.edges.map(({ node }) => node.label)[0] ?? "",
      })) || [
        {
          name: "",
          value: "",
        },
      ],
      stock: variant?.inventory?.aggregated?.availableToSell,
      isInStock: variant?.inventory?.isInStock,
      price: {
        amount:
          variant.prices?.price.value.toString() ||
          variant.prices?.priceRange.max.value.toString() ||
          "0",
        currencyCode:
          variant.prices?.price.currencyCode ||
          variant.prices?.priceRange.max.currencyCode ||
          "",
      },
    };
  });
};
const bigCommerceToVercelCustomFields = (customFields, productId) => {
  return customFields;
};
export const bigCommerceToVercelProduct = (product) => {
  const cursor = product?.cursor;
  const createVercelProductImage = (img) => {
    return {
      url: img ? img.url : "",
      altText: img ? img.altText : "",
      width: 2048,
      height: 2048,
    };
  };
  const createVercelProductVideo = (customFields) => {
    const video = customFields?.find((item) => item?.name === "product_video");
    return video?.value || null;
  };
  const options = product.productOptions.edges.length
    ? bigCommerceToVercelOptions(
        product.productOptions.edges.map((item) => item.node)
      )
    : [];
  const variants = product.variants.edges.length
    ? bigCommerceToVercelVariants(
        product.variants.edges.map((item) => item.node),
        product.entityId
      )
    : [];
  const customFields = product.customFields.edges.length
    ? bigCommerceToVercelCustomFields(
        product.customFields.edges.map((item) => item.node),
        product.entityId
      )
    : [];
  const categories = product?.categories.edges.map(
    (edge) => edge.node.entityId
  );
  return {
    id: product.id.toString(),
    cursor,
    handle: product.path,
    categories: categories,
    availableForSale:
      product.availabilityV2.status === "Available" ? true : false,
    availabilityText: product?.availabilityV2?.description,
    title: product.name,
    description: product.plainTextDescription || "",
    descriptionHtml: product.description ?? "",
    brand: product.brand.name,
    options,
    priceRange: {
      maxVariantPrice: {
        amount:
          product?.prices?.priceRange?.max?.value?.toString() ||
          product?.prices?.price?.value?.toString() ||
          "0",
        currencyCode:
          product?.prices?.priceRange?.max?.currencyCode ||
          product?.prices?.price?.currencyCode ||
          "",
      },
      minVariantPrice: {
        amount:
          product?.prices?.priceRange?.min?.value?.toString() ||
          product?.prices?.price?.value?.toString() ||
          "0",
        currencyCode:
          product?.prices?.priceRange?.min?.currencyCode ||
          product?.prices?.price?.currencyCode ||
          "",
      },
    },
    productVideo: createVercelProductVideo(customFields),
    variants,
    images: product?.images
      ? product?.images?.edges?.map(({ node: img }) =>
          createVercelProductImage(img)
        )
      : [],
    featuredImage: createVercelProductImage(product?.defaultImage),
    customFields,
    seo: {
      title: product?.seo?.pageTitle || product?.name,
      description: product?.seo?.metaDescription || "",
    },
    tags: [product.seo.metaKeywords] || [],
    updatedAt: product.createdAt.utc.toString(),
  };
};
export const bigCommerceToVercelBrands = (brands) => {
  const brandsWithoutNodes = brands?.map((brand) => ({
    name: brand?.node?.name,
    entityId: brand?.node?.entityId,
  }));
  return brandsWithoutNodes;
};
export const bigCommerceToVercelCurrencies = (currencies) => {
  const currenciesList = currencies?.edges?.length
    ? currencies.edges.map((item) => item?.node?.code)
    : [];
  const currenciesWithRate = currencies?.edges?.length
    ? currencies.edges.map((item) => item?.node)
    : [];
  return {
    currenciesWithRate: currenciesWithRate,
    currenciesList: currenciesList,
  };
};

export const bigCommerceToVercelProducts = (products) => {
  const reshapedProducts = [];

  for (const product of products) {
    if (product) {
      const reshapedProduct = bigCommerceToVercelProduct(product);

      if (reshapedProduct) {
        reshapedProducts.push(reshapedProduct);
      }
    }
  }

  return reshapedProducts;
};

export const vercelToBigCommerceSorting = (isReversed, sortKey) => {
  const VercelSorting = {
    RELEVANCE: "RELEVANCE",
    BEST_SELLING: "BEST_SELLING",
    CREATED_AT: "CREATED_AT",
    PRICE: "PRICE",
    FEATURED: "FEATURED",
  };

  if (!sortKey || VercelSorting[sortKey] === undefined) {
    return null;
  }

  if (sortKey === VercelSortKeys.PRICE) {
    return isReversed
      ? vercelToBigCommerceSortKeys.PRICE_ON_REVERSE
      : vercelToBigCommerceSortKeys.PRICE;
  }

  return vercelToBigCommerceSortKeys[sortKey];
};

export const bigCommerceToVercelCollection = (collection) => {
  if (!collection) {
    return {
      handle: "",
      title: "",
      description: "",
      seo: {
        title: "",
        description: "",
      },
      updatedAt: "",
      path: "",
    };
  }

  return {
    handle: collection.entityId.toString() || collection.name,
    title: collection.name,
    description: collection.description,
    seo: {
      title: collection.seo.pageTitle,
      description: collection.seo.metaDescription,
    },
    updatedAt: new Date().toISOString(),
    path: `/search${collection.path}`,
    metaData: collection?.seo,
  };
};

export const bigCommerceToVercelPageContent = (page) => {
  return {
    id: page.entityId.toString(),
    title: page.name,
    handle: page.path.slice(1),
    body: page.htmlBody ?? "",
    bodySummary: page.plainTextSummary ?? "",
    seo: {
      title: page.seo.pageTitle,
      description: page.seo.metaDescription,
    },
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };
};

export const bigCommerceToVercelCartItems = (lineItems, products) => {
  const getItemMapper = (products, isCustomItem = false) => {
    return (item) => {
      const vercelProductFallback = {
        id: "",
        handle: "",
        availableForSale: false,
        title: "",
        description: "",
        descriptionHtml: "",
        options: [],
        priceRange: {
          maxVariantPrice: { amount: "", currencyCode: "" },
          minVariantPrice: { amount: "", currencyCode: "" },
        },
        variants: [],
        featuredImage: {
          url: "",
          altText: "",
          width: 0,
          height: 0,
        },
        images: [
          {
            url: "",
            altText: "",
            width: 0,
            height: 0,
          },
        ],
        seo: { title: "", description: "" },
        tags: [],
        updatedAt: "",
      };
      let product;
      let selectedOptions;

      if (isCustomItem) {
        product = vercelProductFallback;
        selectedOptions = [{ name: "", value: "" }];
      } else {
        const productData = products.filter(
          ({ productId }) => productId === item.productEntityId
        )[0]?.productData;

        product = productData
          ? bigCommerceToVercelProduct(productData)
          : vercelProductFallback;
        selectedOptions = item.selectedOptions.map((option) => ({
          name: option.name,
          value:
            option.value ||
            option.text ||
            option.number?.toString() ||
            option.fileName ||
            "",
        }));
      }

      return {
        id: item.entityId.toString(), // NOTE: used as lineId || lineItemId
        quantity: item.quantity,
        cost: {
          totalAmount: {
            amount:
              item.extendedListPrice.value.toString() ||
              item.listPrice.value.toString() ||
              "0",
            currencyCode:
              item.extendedListPrice.currencyCode ||
              item.listPrice.currencyCode ||
              "",
          },
        },
        merchandise: {
          id: isCustomItem
            ? item.entityId.toString()
            : item.variantEntityId.toString(),
          title: `${item.name}`,
          selectedOptions,
          product,
        },
      };
    };
  };

  const { physicalItems, digitalItems, customItems } = lineItems;
  const areCustomItemsInCart = customItems.length > 0;
  const line1 = physicalItems.map((item) => getItemMapper(products)(item));
  const line2 = digitalItems.map((item) => getItemMapper(products)(item));
  const line3 = areCustomItemsInCart
    ? customItems.map((item) =>
        getItemMapper(products, areCustomItemsInCart)(item)
      )
    : [];

  return [...line1, ...line2, ...line3];
};

export const bigCommerceToVercelCart = (
  cart,
  products,
  checkout,
  checkoutUrl
) => {
  return {
    id: cart.entityId,
    checkoutUrl: checkoutUrl ?? "",
    cost: {
      subtotalAmount: {
        amount: checkout.subtotal.value.toString(),
        currencyCode: checkout.subtotal.currencyCode,
      },
      totalAmount: {
        amount: checkout.grandTotal.value.toString(),
        currencyCode: checkout.grandTotal.currencyCode,
      },
      totalTaxAmount: {
        amount: checkout.taxTotal.value.toString(),
        currencyCode: checkout.taxTotal.currencyCode,
      },
      shippingCost: {
        currencyCode: checkout?.shippingCostTotal?.currencyCode,
        amount: checkout?.shippingCostTotal?.value,
      },
      coupons: {
        code: checkout?.coupons?.[0]?.code,
        couponType: checkout?.coupons?.[0]?.couponType,
        currencyCode: checkout?.coupons?.[0]?.discountedAmount?.currencyCode,
        amount: checkout?.coupons?.[0]?.discountedAmount?.value,
      },
    },
    lines: bigCommerceToVercelCartItems(cart.lineItems, products),
    totalQuantity: cart.lineItems.totalQuantity,
  };
};

export const bigCommerceToVercelCheckoutCoupon = (checkout) => {
  return {
    subtotalAmount: {
      amount: checkout?.subtotal?.value,
      currencyCode: checkout?.subtotal?.currencyCode,
    },
    totalAmount: {
      amount: checkout?.grandTotal?.value,
      currencyCode: checkout?.grandTotal?.currencyCode,
    },
    totalTaxAmount: {
      amount: checkout?.taxTotal?.value,
      currencyCode: checkout?.taxTotal?.currencyCode,
    },
    shippingCost: {
      currencyCode: checkout?.shippingCostTotal?.currencyCode,
      amount: checkout?.shippingCostTotal?.value,
    },
    coupons: {
      code: checkout?.coupons?.[0]?.code,
      couponType: checkout?.coupons?.[0]?.couponType,
      currencyCode: checkout?.coupons?.[0]?.discountedAmount?.currencyCode,
      amount: checkout?.coupons?.[0]?.discountedAmount?.value,
    },
  };
};

export const bigCommerceToFilters = (filters, filterNames) => {
  // single out the filters according to the names provided
  let filteredFilters = filterNames?.map(
    (name) =>
      filters?.filter((filter) => {
        return filter?.node?.name === name;
      })?.[0]
  );

  // make an array of filters with name and their values
  let bigCommFilters = filteredFilters?.map((filter) => ({
    name: filter?.node?.name,
    values: filter?.node?.attributes?.edges?.map(({ node }) => node?.value),
  }));

  // map the bigCommFilters acording to our use in website
  const wearThatFilters = bigCommFilters
    ?.map((filter) =>
      filter?.key !== undefined && filter?.name !== undefined
        ? {
            key: filter?.name?.toLowerCase(),
            name: filter?.name?.toUpperCase(),
            options: filter?.values?.map((value) => ({
              title: value,
              slug: value,
            })),
          }
        : null
    )
    .filter(Boolean);
  return wearThatFilters;
};

export const bigCommCategoriesListToFiltersList = (bigCommCategoriesList) => ({
  key: "category",
  name: "PRODUCT TYPE",
  options: bigCommCategoriesList?.map((category) => ({
    title: category?.title,
    slug: category?.handle,
    seo: category?.seo?.description,
  })),
});
