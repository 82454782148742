import {
  COLLECTION_VALUES,
  SHOP_FILTERS,
} from "@/public/Constants/EnumConstants";
import { getCollections, getFilters } from "./bigcommerce";
import { bigCommCategoriesListToFiltersList } from "./bigcommerce/mappers";
import { sorting } from "./bigcommerce/constants";

export const createUrl = (pathname, params) => {
  const paramsString = params?.toString();
  const queryString = `${paramsString.length ? "?" : ""}${paramsString}`;

  return `${pathname}${queryString}`;
};

export const ensureStartsWith = (stringToCheck, startsWith) =>
  stringToCheck.startsWith(startsWith)
    ? stringToCheck
    : `${startsWith}${stringToCheck}`;

export const validateEnvironmentVariables = () => {
  const requiredEnvironmentVariables = [
    "BIGCOMMERCE_CANONICAL_STORE_DOMAIN",
    "BIGCOMMERCE_ACCESS_TOKEN",
    "BIGCOMMERCE_CUSTOMER_IMPERSONATION_TOKEN",
  ];
  const missingEnvironmentVariables = [];

  requiredEnvironmentVariables.forEach((envVar) => {
    if (!process.env[envVar]) {
      missingEnvironmentVariables.push(envVar);
    }
  });

  if (missingEnvironmentVariables.length) {
    throw new Error(
      `The following environment variables are missing. Your site will not work without them. Read more: https://vercel.com/docs/integrations/shopify#configure-environment-variables\n\n${missingEnvironmentVariables.join(
        "\n"
      )}\n`
    );
  }

  if (
    process.env.SHOPIFY_STORE_DOMAIN?.includes("[") ||
    process.env.SHOPIFY_STORE_DOMAIN?.includes("]")
  ) {
    throw new Error(
      "Your `SHOPIFY_STORE_DOMAIN` environment variable includes brackets (ie. `[` and / or `]`). Your site will not work with them there. Please remove them."
    );
  }
};
export const checkCartItemsStockAvailability = (products, setOutOfStock) => {
  let temp = false;
  products?.forEach(async (product) => {
    const { quantity, merchandise } = product;
    const selectedVariant = merchandise?.product?.variants?.find(
      (variant) => variant?.id === merchandise?.id
    );
    if (selectedVariant?.stock == 0 || selectedVariant?.stock < quantity) {
      temp = true;
    }
  });
  setOutOfStock(temp);
  return;
};

export const createFiltersArray = ({ collection, color, size }) => {
  let productAttributesArr = [];
  if (collection) {
    productAttributesArr = [
      {
        attribute: "collection",
        values:
          collection instanceof Array
            ? collection?.map((name) => COLLECTION_VALUES?.[name] || name)
            : [COLLECTION_VALUES?.[collection]],
      },
    ];
  }
  if (size) {
    productAttributesArr = [
      ...productAttributesArr,
      {
        attribute: "Size",
        values: size instanceof Array ? size?.map((name) => name) : [size],
      },
    ];
  }
  if (color) {
    productAttributesArr = [
      ...productAttributesArr,
      {
        attribute: "Color",
        values: color instanceof Array ? color?.map((name) => name) : [color],
      },
    ];
  }

  return productAttributesArr;
};

export const wearThatProductFilters = async () => {
  // get normal Filters from BigComm
  const bigCommFilters = await getFilters(["Size", "Color"]);
  // get Categories List from BigComm
  const bigCommCategoriesList = await getCollections();
  // map BigComm categories List to our filters
  let categoriesList = bigCommCategoriesListToFiltersList(
    bigCommCategoriesList
  );
  // Slugs to not show Categories in the Filters
  let slugsToIgnore = [
    "100",
    "101",
    "103",
    "106",
    "108",
    "117",
    "118",
    "120",
    "123",
    "124",
    "125",
    "126",
    "127",
    "135",
    "136",
    "148",
    "171",
  ];
  const filteredCategoriesOptions = categoriesList?.options?.filter(
    (option) => !slugsToIgnore?.includes(option?.slug)
  );
  categoriesList.options = filteredCategoriesOptions;
  // return all filters final list
  return [...SHOP_FILTERS, ...bigCommFilters, categoriesList];
};

export const buildGetProductsParams = (
  searchParams,
  forwardCursor,
  backwardCursor
) => {
  const {
    sort,
    q: searchValue,
    collection,
    size,
    color,
    category,
    price,
    brand,
  } = searchParams;
  const parsedPrice = price && JSON.parse(price);
  const priceFilter = parsedPrice && {
    minPrice: parsedPrice[0],
    maxPrice: parsedPrice[1],
  };

  const { sortKey, reverse } =
    sorting?.find((item) => item?.slug === sort) ||
    (priceFilter && sorting[3]) ||
    sorting[5];

  const productAttributesArr = createFiltersArray({ color, size });
  const categoryEntityIds = Array?.isArray(category)
    ? category?.map((v) => Number(v) || "")
    : category
    ? [Number(category)]
    : [];
  const brandEntityIds = Array?.isArray(brand)
    ? brand?.map((v) => Number(v) || "")
    : brand
    ? [Number(brand)]
    : [];

  let params = {
    sortKey,
    reverse,
    query: searchValue,
    productAttributesArr,
    categoryEntityIds,
    priceFilter,
    brandEntityIds,
  };

  if (forwardCursor) {
    params = { ...params, endCursor: forwardCursor };
  } else if (backwardCursor) {
    params = { ...params, startCursor: backwardCursor };
  }

  return params;
};

export const getDiscountedLineItems = (cartItems) => {
  let bundleDiscountInfo = null;
  let itemsWithDiscountAmount = null;
  if (cartItems?.collection_category?.meta_description) {
    const x = cartItems?.collection_category?.meta_description?.substr(
      1,
      cartItems?.collection_category?.meta_description?.length - 2
    );
    bundleDiscountInfo = JSON?.parse(x);
    console.log("CART ITEMS DEKHNI HAIN", cartItems);
    itemsWithDiscountAmount = spreadDiscountToItems(
      bundleDiscountInfo?.price + bundleDiscountInfo?.discount,
      bundleDiscountInfo?.price,
      cartItems?.collection_products?.products
    );
    console.log("testing the function", itemsWithDiscountAmount);
    return itemsWithDiscountAmount;
  }
};

export const spreadDiscountToItems = (
  bundleFullPrice,
  bundleDiscountedPrice,
  lineItems
) => {
  let bundleDiscount = bundleFullPrice - bundleDiscountedPrice;

  const discountedLineItems = [];

  for (let i = 0; i < lineItems.length; i++) {
    if (bundleDiscount <= 0) {
      return discountedLineItems;
    }
    const lineItem = lineItems[i];
    let lineDiscount = 0;
    if (lineItem.price > bundleDiscount) {
      lineDiscount = bundleDiscount;
    } else {
      lineDiscount = lineItem.price;
    }
    const discountedLineItem = {
      id: lineItem.line_id,
      discounted_amount: lineDiscount,
    };
    discountedLineItems.push(discountedLineItem);
    bundleDiscount -= lineDiscount;
  }
  return discountedLineItems;
};
export const calculateCollectionPrice = (metaDescription, price) => {
  try {
    // Remove the single quotes around the meta_description string
    const jsonString = metaDescription.replace(/^'|'$/g, "");

    // Parse the JSON string into an object
    const metaObject = JSON.parse(jsonString);

    // Extract the discount value and calculate the final collection price
    const discount = parseInt(metaObject?.discount);
    const price = parseInt(metaObject?.price);
    return price ;
  } catch (error) {
    console.error(
      "Error while parsing meta_description or calculating the price:",
      error
    );
    return null; // Return null or handle error accordingly
  }
};

export const updateLocalStorage = (variantIds) => {
  const bundlesData = JSON.parse(localStorage?.getItem("bundles"));

  function arraysEqual(arr1, arr2) {
    return (
      arr1.length === arr2?.length &&
      arr1?.every((item) => arr2?.includes(item))
    );
  }

  // Filter the bundlesData
  const filteredBundles = bundlesData?.filter((bundle) => {
    // Extract variant IDs from the bundle
    const bundleVariantIds = bundle?.map((item) => item?.variantId);

    // Check if the extracted variant IDs match the variantIds array
    return !arraysEqual(bundleVariantIds, variantIds);
  });
  if (filteredBundles?.length > 0) {
    localStorage?.setItem("bundles", JSON?.stringify(filteredBundles));
  } else {
    localStorage?.removeItem("bundles");
  }
};
